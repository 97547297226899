♂<template>
  <header :class="'header ' + `${ !toggleFlag ? 'is-hidden' : '' }`" >
    <div class="container-fluid">
      <div class="top-header row">
        <div class="col-5">
          <a href="/" class="logo" data-mixpanel="landing_logo_click">
            <img :src="switchLogo" alt />
            <span>COUNTABLE WEB PRODUCTIONS</span>
          </a>
        </div>

        <div class="col-7">
          <div class="header-right">
            <div class="menu-icon" v-html="switchMenu" @click="openMenu" />
            <router-link
              :to="redirectToHome('contact-form')"
              @click.native="scrollFix('#contact-form')"
            >
              <a
                class="lets-btn"
                data-mixpanel="landing_cta_lets_talk_top"
                onclick="gtag_report_conversion()"
              >
                {{ switchText }}
              </a>
            </router-link>
          </div>
        </div>
        <div id="mySidenav" class="navigation">
          <a ref="javascript:void(0)" class="closebtn" @click="closeMenu"
            >&times;</a
          >
          <ul class="nav-sub-menu">
            <li>
              <router-link :to="redirectToPortfolio">
                <a
                  data-mixpanel="landing_menu_portfolio"
                  @click="closeMenu"
                  >Portfolio</a
                >
              </router-link>
            </li>

            <li>
              <router-link
                :to="redirectToHome('industries-sec')"
                @click.native="scrollFix('#industries-sec')"
              >
                <a
                  href="#industries-sec"
                  data-mixpanel="landing_menu_industries"
                  @click="closeMenu"
                  >Industries</a
                >
              </router-link>
            </li>
            <li>
              <router-link
                :to="redirectToHome('our-work')"
                @click.native="scrollFix('#our-work')"
              >
                <a
                  href="#our-work"
                  data-mixpanel="landing_our_works"
                  @click="closeMenu"
                  >Our Work</a
                >
              </router-link>
            </li>
            <li>
              <router-link
                :to="redirectToHome('stats-section')"
                @click.native="scrollFix('#stats-section')"
              >
                <a
                  href="#stats-section"
                  data-mixpanel="landing_menu_stats"
                  @click="closeMenu"
                  >Stats</a
                >
              </router-link>
            </li>
            <li v-for="service in servicesList" :key="service.id">
              <a
                :href="`/services/${service.id}`"
                :data-mixpanel="'landing_menu_' + service.id"
                @click="closeMenu"
                >{{ service.header }}</a
              >
            </li>
            <li>
              <router-link :to="redirectToBlog">
                <a data-mixpanel="landing_menu_blog" @click="closeMenu">Blog</a>
              </router-link>
            </li>
            <li>
              <router-link :to="redirectToJobPosting">
                <a data-mixpanel="landing_menu_job" @click="closeMenu">Job Posting</a>
              </router-link>
            </li>
            <li>
              <router-link :to="redirectToTeam">
                <a data-mixpanel="landing_menu_team" @click="closeMenu">Team</a>
              </router-link>
            </li>
            <li>
              <router-link :to="redirectToCareer">
                <a data-mixpanel="landing_menu_careers" @click="closeMenu"
                  >Careers</a
                >
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="showFlag" class="ukraine-flag row" :class="{ 'is-hidden': !toggleFlag }">
        <div class="ukraine-blue">
            <span class="top-text">Countable stands with the people of Ukraine. </span>
            <a ref="javascript:void(0)" class="closebtn" @click="toggleUkraineFlag"
            >&times;</a
          >
        </div>
        <div class="ukraine-yellow">
            <span class="bottom-text"> Countable stands with the people of Ukraine. </span> <span> We are firmly anti-war and implore Russia to halt their invasion immediately. </span>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showFlag: false,
      toggleFlag: true,
      lastScrollPosition: 0,
      scrollOffset: 250,
    };
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    redirectToBawkBlog() {
      return {
        name: "BawkBlog",
      };
    },
    redirectToBlog() {
      return {
        name: "Blog",
      };
    },
    redirectToJobPosting() {
      return {
        name: "JobPosting",
      };
    },
    redirectToTeam() {
      return {
        name: "Team",
      };
    },
    redirectToCareer() {
      return {
        name: "Careers",
      };
    },
    redirectToPortfolio() {
      return {
        name: "Portfolio",
      };
    },
    servicesList() {
      return this.$store.getters.getServiceList.map((service) => {
        return {
          id: service.service_slug,
          header: service.service_name,
        };
      });
    },

    switchHeader() {
      return this.$route.path == "/" ? "" : "inverted-header";
    },

    switchLogo() {
      const logoName = this.$route.path == "/" ? "" : "";
      return require(`@/assets/media/landing/logo${logoName}.svg`);
    },

    switchMenu() {
      return "<div></div><div></div><div></div>";
    },
    switchText() {
      return this.$route.path.includes("/services")
        ? "BOOK A DEMO"
        : "LET'S TALK";
    },
  },
  methods: {
    scrollFix(hashtag) {
      this.$route.path === "/" ? (location.href = `/${hashtag}`) : "";
    },
    redirectToHome(section) {
      return {
        name: "Index",
        hash: "#" + section,
        from: "Portfolio",
      };
    },
    redirectToService(service) {
      window.location = `services/${service}`;
      return {
        name: "Services",
        params: {
          service: service,
        },
      };
    },
    openMenu() {
      document.getElementById("mySidenav").style.width = "100%";
      documement.body.style.overflowY = 'hidden';
    },
    closeMenu() {
      document.getElementById("mySidenav").style.width = "0";
      documement.body.style.overflowY = 'auto';
    },
    toggleUkraineFlag() {
      this.showFlag = !this.showFlag
    },
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      this.toggleFlag = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
  },
};
</script>

<style></style>
