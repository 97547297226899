<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-column-right">
        <div class="footer-title">
          OUR COMPANY
          <div class="footer-title-bar"></div>
        </div>

        <ul class="footer-list">
          <li>
            <a
              href="https://countable-ops-manual.readthedocs.io/index.html"
              target="_blank"
            >Ops Manual</a>
          </li>
          <li>
            <a
              href="https://sites.google.com/countable.ca/countable-staff/home"
              target="_blank"
            >Staff Site</a>
          </li>
          <li>
            <a
              href="https://countable-ops-manual.readthedocs.io/peopleops/recruiting/APPLICANT_GUIDE.html?highlight=applicant#applicant-guide"
              target="_blank"
            >Applicant's Guide</a>
          </li>
        </ul>
      </div>
      <div class="footer-column-left">
        <div class="footer-title">
          PRODUCTS
          <div class="footer-title-bar"></div>
        </div>

        <ul class="footer-list">
          <li>
            <a href="https://www.htmlcommentbox.com/" target="_blank">HTML Comment Box</a>
          </li>
          <li>
            <a href="https://www.bawkbox.com" target="_blank">Bawkbox</a>
          </li>
          <li>
            <a href="https://cryptsend.io/" target="_blank">CryptSend</a>
          </li>
        </ul>
      </div>

      <div class="footer-column-social-media">
        <a
          href="https://www.linkedin.com/company/countable-web-productions"
          target="blank"
          class="social-icon"
        >
          <img src="@/assets/media/landing/linkedin.svg" alt="LinkedIn" />
        </a>

        <a href="https://www.instagram.com/countableweb/" target="blank" class="social-icon">
          <img src="@/assets/media/landing/instagram.svg" alt="Instagram" />
        </a>

        <a href="#" target="blank" class="social-icon">
          <img src="@/assets/media/landing/dribble.svg" alt="Dribble" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
