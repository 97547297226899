/* eslint-disable no-undef */
$(function() {
  /*#############################################################|
    |                        MIXPANEL EVENT TRACKER
    *##############################################################*/

  /* Landing =========================================== */

  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/index.html"
  ) {
    mixpanel.track("visited_page");
  }

  $("a[data-mixpanel]").on("click", function() {
    const event = $(this).attr("data-mixpanel");
    mixpanel.track(event);
    console.log(`Tracked event ${event}`);
  });
  //
  // $("#cta-get-free-consultation-top").on("click", function() {
  //     mixpanel.track("clicked_cta_top_consultation");
  //     console.log("clicked cta top");
  // });
  //
  // $("#cta-free-consultation-mid").on("click", function() {
  //     mixpanel.track("clicked_cta_mid_consultation");
  //     console.log("clicked mid");
  // });
  //
  // $("#openCountableVideo").on("click", function() {
  //     mixpanel.track("clicked_countable_video");
  //     console.log("clicked video");
  // });

  /* Portfolio =========================================== */

  if (window.location.pathname.includes("portfolio")) {
    mixpanel.track("visited_portfolio_page");
    console.log("visited_portfolio_page");
  }

  if (window.location.pathname.includes("queue")) {
    mixpanel.track("visited_queue_management");
    console.log("visited_queue_management");
  }

  if (window.location.pathname.includes("cortico")) {
    mixpanel.track("visited_cortico");
    console.log("visited_cortico");
  }

  $(".queue-case-studies").waypoint({
    handler: function(direction) {
      if (direction === "down") {
        console.log("tracking queue case studies");
        mixpanel.track("queue_scroll_case_studies");
      }
    },
    offset: "75%"
  });

  //
  // $(".main-portfolio-cta").on("click", function() {
  //     mixpanel.track("clicked_cta_portfolio");
  //     console.log("clicked cta portfolio");
  // })
});
