import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      location.href = to.hash;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "Index",
      component: () => import("@/views/Index"),
    },
    {
      path: "/portfolio",
      name: "Portfolio",
      component: () => import("@/views/Portfolio"),
    },
    {
      path: "/email",
      name: "Email",
      component: () => import("@/views/Email"),
    },
    {
      path: "/services/:service",
      name: "Services",
      component: () => import("@/views/Services"),
    },
    {
      path: "/blog",
      name: "Blog",
      component: () => import("@/views/Blog"),
    },
    {
      path: "/blog/:id",
      name: "BlogDetail",
      component: () => import("@/views/BlogDetail"),
    },
    {
        path: "/job-posting",
        name: "JobPosting",
        component: () => import("@/views/JobPosting"),
    },
    {
      path: "/bawkbox-blog",
      name: "BawkBlog",
      component: () => import("@/views/BawkboxBlog"),
    },
    {
      path: "/team",
      name: "Team",
      component: () => import("@/views/Team"),
    },
    {
      path: "/careers",
      name: "Careers",
      component: () => import("@/views/Careers"),
    },
    {
      path: "/vr",
      name: "VirtualReality",
      component: () => import("@/views/VR"),
    },
    {
      path: "*",
      name: "PageNotFound",
      component: () => import("@/views/PageNotFound"),
    },
  ],
});
