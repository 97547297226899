import c0 from "../../media/landing/portfolio/1.png";
import c1 from "../../media/landing/portfolio/2.png";
import c2 from "../../media/landing/portfolio/3.png";
import c3 from "../../media/landing/portfolio/4.png";
import c4 from "../../media/landing/portfolio/5.png";
import c5 from "../../media/landing/portfolio/6.png";
import c6 from "../../media/landing/portfolio/7.png";
import c7 from "../../media/landing/portfolio/8.png";
import c8 from "../../media/landing/portfolio/9.png";

/* Initial Carousel Config =========================================== */

let start = 0;
let end = 3;
let max_per_row = 3;
const $ = window.$;

let countable_customers = [
  {
    id: 0,
    name: "Government of British Columbia",
    url: "https://www2.gov.bc.ca/",
    img: c0
  },
  { id: 1, name: "Corvus Energy", url: "https://corvusenergy.com/", img: c1 },
  { id: 2, name: "Ecotrust", url: "http://ecotrust.ca/", img: c2 },
  {
    id: 3,
    name: "Kensington Medical Clinic",
    url: "https://www.kensingtonmedicalclinic.com/",
    img: c3
  },
  { id: 4, name: "PHEMI", url: "https://www.phemi.com/", img: c4 },
  {
    id: 5,
    name: "WestHaven Group",
    url: "http://thewesthavengroup.com/",
    img: c5
  },
  { id: 6, name: "Wonder Giant", url: "https://wondergiant.com", img: c6 },
  { id: 7, name: "THISFISH", url: "http://www.countable.ca/", img: c7 },
  { id: 8, name: "Clarius Corp", url: "https://clarius.com/", img: c8 }
];

class Carousel {
  static renderCustomer() {
    // console.log("rendering carousel...");
    // console.log(`Start=${start} / end=${end} / total=${countable_customers.length}`);
    $(".customers-list").html(""); //refresh carousel

    for (let i = start; i < end; i++) {
      let decorator = `
   
                <a href="${countable_customers[i].url}" target="_blank" class="customer-link">
                <img class="customer" src="${countable_customers[i].img}">
                </a>
                
`;
      $(".customers-list").append(decorator); //then render files
    }

    $(".customer").unbind("hover");
    //hover effect
    $(".customer").hover(function() {
      console.log("hover");
      $(this).toggleClass("shadow-drop-center");
    });
  }

  static next() {
    //check if we reached last item

    // console.log(`Start=${start} / end=${end} / total=${countable_customers.length}`);

    if (end == countable_customers.length - 1) {
      start = 0;
      end = max_per_row;
      this.renderCustomer();
    } else {
      if (end < countable_customers.length) {
        start++;
        end++;
        this.renderCustomer();
      }
    }
  }

  static previous() {
    // console.log(`Start=${start} / end=${end} / total=${countable_customers.length}`);

    if (start == 0) {
      start = countable_customers.length - max_per_row;
      end = countable_customers.length;
      this.renderCustomer();
    } else {
      if (start > 0) {
        start--;
        end--;
        this.renderCustomer();
      }
    }
  }
}

export { Carousel };
