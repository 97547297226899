const testimonials = [
  {
    id: 0,
    reviewer: "Shawn Brown West",
    group: "Haven Group",
    testimony:
      '"Clark is patient and calm and absolutely a problemsolver. He is very much about being driven to understandand solve a problem. His team is responsive and nice to work with. I will work with this team for many years to come and look forward to continuing to achieve results with these great people!"',
    image_top: "t-img.png",
    group_logo: "bootom-img.png"
  },
  {
    id: 1,
    reviewer: "Laurent",
    group: "Clarious Corp",
    testimony:
      '"Your guidance and leadership has been instrumental in building our Cloud infrastructure it is a great competitive advantage for Clarius."',
    image_top: "testimonial/customers-projects/clarius.png",
    group_logo: "portfolio/2.png"
  },
  {
    id: 2,
    reviewer: "Jeremy",
    group: "Kensington Medical Clinic",
    testimony:
      '"Thanks for all the work and for treating us so well. At least you know you have helped Kensington become likely the most innovative and patient friendly clinic in BC."',
    image_top: "testimonial/customers-projects/kensington.png",
    group_logo: "portfolio/4.png"
  }
];

const ourProjects = [
  {
    id: "amn",
    projectName: "ABORIGINAL MAPPING NETWORK",
    url: "http://nativemaps.org/",
    description:
      "The AMN uses spatial mapping data to provide strategic resources for practitioners of traditional knowledge mapping around the world.",
    imgLocation: ""
  },
  {
    id: "bawkbox",
    projectName: "BAWKBOX",
    url: "http://bawkbox.com/",
    description:
      "BawkBox is a Countable product that makes widget creation on your own website easy.",

    imgLocation: "media/portfolio/bawkbox.png"
  },
  {
    id: "htmlcommentbox",
    projectName: "HTMLCOMMENTBOX",
    url: "http://htmlcommentbox.com",
    description:
      "A Countable product which provides an easy way to get comments and add interactivity to your website.",
    imgLocation: "media/portfolio/htmlcommentbox.png"
  },
  {
    id: "cryptsend",
    projectName: "CRYPTSEND",
    url: "https://cryptsend.io/",
    description:
      "A Countable product which allows you to share files with people you trust, and not all the cloud providers in between.",
    imgLocation: "media/portfolio/cryptsend.png"
  },
  {
    id: "homeworth",
    projectName: "WESTHAVEN GROUP",
    url: "https://homeworthbc.ca/",
    description:
      "We've created real estate algorithms, tools and interfaces to allow buyers to estimate home values online to help them to make the best decision.",
    imgLocation: "media/portfolio/westhaven.png"
  },
  {
    id: "kensington",
    projectName: "KENSINGTON MEDICAL CLINIC",
    url: "https://www.kensingtonmedicalclinic.com/",
    description:
      "Kensington walk-in and pediatric clinic website mainly offers patient queue display and booking for woman's health clinic, pediatrics or family doctor.",
    imgLocation: "media/portfolio/kensington.png"
  },
  {
    id: "clarius",
    projectName: "CLARIUS",
    url: "http://clarius.com/",
    description:
      "Clarius is a wireless portable ultrasound provider, assisting many medical specialties such as anesthesia, emergency, OB/GYN and even sports medicine.",
    imgLocation: "media/portfolio/clarius.png"
  }
];

const teamMembers = [
  {
    id: 0,
    name: "Clark",
    role: "Dev-Ops Generalist",
    testimonial: "",
    img: "@/assets/media/landing/team/t1.png"
  },
  {
    id: 1,
    name: "Valentina",
    role: "Artist",
    testimonial:
      '" We work from anywhere at any time or whenever inspiration takes us. This is the prototype that many businesses will adapt as the digital world evolves. We are at the frontline."',
    img: "@/assets/media/landing/team/t2.png"
  },
  {
    id: 2,
    name: "Joao",
    role: "Front-end Developer",
    testimonial:
      '"Working at Countable gives me location freedom and this is priceless. Being able to work from anywhere can save you a lot of time and make your life way more efficient."',
    img: "@/assets/media/landing/team/t3.png"
  },
  {
    id: 3,
    name: "Gian",
    role: "Full-stack Developer",
    testimonial: "",
    img: "@/assets/media/landing/team/t7.png"
  },
  {
    id: 4,
    name: "Ferdinand",
    role: "Back-end Developer",
    testimonial:
      '"I can work from anywhere and I still able to continue learning advanced web technologies."',
    img: "@/assets/media/landing/team/t8.png"
  },
  {
    id: 5,
    name: "Aaron",
    role: "Full-Stack Developer",
    testimonial:
      '"Our team pushes you to reach your potential and the way we are running the company is unlike any other."',
    img: "@/assets/media/landing/team/t9.png"
  },
  {
    id: 6,
    name: "Joao Caldas",
    role: "UX/UI",
    testimonial:
      '"Countable allows me to collaborate with incredible team members from around the world, while enables great support, work-life balance, and flexibility."',
    img: "@/assets/media/landing/team/t12.jpg"
  },
  {
    id: 7,
    name: "Denis Calixto",
    role: "Back-end Developer",
    testimonial: "",
    img: "@/assets/media/landing/team/t13.jpg"
  }
];

const services = [
  {
    id: 0,
    service_name: "Queue Management",
    service_slug: "queue_management",
    title: "QUEUE MANAGEMENT",
    description: "Simplify and automate customer service flows.",
    image: {},
    benefit_title: " Software design/Benefits for Queue Management:",
    benefits: [
      { description: "Online Booking", image: {}, gif: {} },
      {
        description: "Queue position and wait time estimation",
        image: {},
        gif: {}
      },
      { description: "Wayfinding", image: {}, gif: {} }
    ],
    features: [],
    caseStudies: [
      {
        client: "BC MINISTRY OF CITIZEN SERVICES",
        clientLogo: "",
        description:
          "We contributed to the development of an open source Queue System for ServiceBC.",
        features: [
          "Accommodates multiple locations with unique",
          "Manage ITA Exams/Room Bookings/Service Appointments",
          "Put citizens on hold during service and resume",
          "Designed for reception or direct counter offices",
          "Open Source, no vendor lock-in",
          "Digital signage"
        ],
        projectTeaserImg: "",
        testimonial: {
          name: "Karim, Ministry of Citizen Services",
          testimony:
            "Thank you for your innovative, customer centric approach to our application updates. Countable exceeded our expectations. Their work allowed Service BC employees to serve the citizens of British Columbia with greater efficiency.”"
        }
      },
      {
        client: "KENSINGTON MEDICAL CLINIC",
        clientLogo: "",
        description:
          "Countable built a custom-designed queue management system to manage hundreds of patients per day, drastically cutting wait times and personnel costs.",
        features: [
          "Displays queue status, availability and staff & patient scheduling online",
          "Automated reminders and satisfaction surveys sent to users",
          "Virtual (video) or in-person appointments",
          "Wait time estimation and queue length",
          "“Take a Number” option",
          "Digital signage"
        ],
        projectTeaserImg: "",
        testimonial: {
          name: "Jeremy, Clinic Manager",
          testimony:
            "Thanks for all the work and for treating us so well. At least you know you have helped Kensington become likely the most innovative and patient friendly clinic in BC.”"
        }
      }
    ]
  },
  {
    id: 1,
    service_name: "Cortico Patient Management",
    service_slug: "cortico",
    title: "CORTICO: AUTOMATED PATIENT ENGAGEMENT",
    description: "Patient intake automation for clinics using OSCAR.",
    image: {},
    benefit_title:
      "Software designed with physicians & clinic managers that eliminates overhead by:",
    benefits: [
      {
        description:
          "Reducing MOA hours spent on registration, bookings and queues",
        image: {},
        gif: {}
      },
      {
        description: "Improving efficiency by streamlining appointment times",
        image: {},
        gif: {}
      },
      {
        description: "Increasing profit by 30% within 1-2 years",
        image: {},
        gif: {}
      },
      {
        description:
          "Enhancing patient and doctor satisfaction by reducing in-clinic wait times",
        image: {},
        gif: {}
      }
    ],
    features: [
      {
        title: "Patient self-registration",
        description:
          "Reduce MOA/overhead costs and improve patient demographic collection. Less time waiting in line means happier patients and staff."
      },
      {
        title: "Online booking",
        description:
          "It’s quick and easy - any patient can book, confirm and cancel their own appointment."
      },
      {
        title: "Virtual Care, Telehealth",
        description:
          "Connect with patients to provide visual healthcare and prescriptions from your phone, tablet or computer - any time, 24/7."
      },
      {
        title: "Queue management",
        description:
          "Patients are issued a  number in queue and can  track their position on their  phone - no app required!"
      },
      {
        title: "Appointment reminder",
        description:
          "Automatic email and/or text message appointment reminders drastically reduce losses from no-shows or last-minute cancellations."
      },
      {
        title: "Improved fee collection",
        description:
          "Non-MSP covered fees collected via a web-based payment system."
      },
      {
        title: "Control patient visit flow",
        description:
          "A flexible walk-in appointment system to help streamline the clinic appointment schedule and smooth out peaks and troughs."
      },
      {
        title: "Automated patient surveys",
        description:
          "Enhance patient and doctor satisfaction and get better online reviews."
      }
    ],

    caseStudies: [
      {
        client: "KENSINGTON MEDICAL CLINIC",
        clientLogo: "",
        description:
          "Countable built a custom-designed queue management system to manage hundreds of patients per day, drastically cutting wait times and personnel costs.",
        features: [
          "Displays queue status, availability and staff & patient scheduling online",
          "Automated reminders and satisfaction surveys sent to users",
          "Virtual (video) or in-person appointments",
          "Wait time estimation and queue length",
          "“Take a Number” option",
          "Digital signage"
        ],
        projectTeaserImg: "",
        testimonial: {
          name: "Jeremy, Clinic Manager",
          testimony:
            "Thanks for all the work and for treating us so well. At least you know you have helped Kensington become likely the most innovative and patient friendly clinic in BC.”"
        }
      }
    ]
  },
  {
    id: 2,
    service_name: "Geographic Information System",
    service_slug: "gis",
    title: "GEOGRAPHIC INFORMATION SYSTEM (GIS) SERVICES",
    description:
      "Custom web-based mapping services for increased flexibility and cost savings.",
    image: {},
    benefit_title: "The benefits of web-based GIS:",
    benefits: [
      {
        description:
          "Beautiful & intuitive interactive map experiences for visitors",
        image: {},
        gif: {}
      },
      {
        description:
          "Simpler and easier for visitors to understand & use than GIS software",
        image: {},
        gif: {}
      },
      {
        description:
          "Easier to share directly on the web, and to integrate with other applications",
        image: {},
        gif: {}
      }
    ],
    features: [
      {
        title: "Web-based maps",
        description: "built on google maps, Leaflet, and Mapbox"
      },
      {
        title: "Open spatial technologies",
        description: "GDAL, PostGIS and GeoDjango"
      },
      {
        title: "Powerful",
        description: "spatial queries and analysis"
      },
      {
        title: "Interactive",
        description: "data editing tools"
      },
      {
        title: "Visually intuitive",
        description: "spatial data visualization and exploration"
      },
      {
        title: "Integration",
        description: "With spatial data sets"
      }
    ],
    caseStudies: [
      {
        client: "FIRST PEOPLES' CULTURAL COUNCIL",
        clientLogo: "",
        description:
          "Countable built the First Peoples' Language Map, a custom-designed web-based mapping service for the FPCC.",
        features: [],
        projectTeaserImg: "",
        testimonial: {
          name: "Daniel, FirstVoices Development Manager",
          testimony:
            "For years we have viewed our map as one of the most important and relevant tools FPCC has for both indigenous and non-indegenous people. We are certain that the improved user-interface and added functionality will make it easier for indigenous people to share meaningful information about their art, language and heritage, as well as serve as an educational piece for all."
        }
      },
      {
        client: "PROVINCE OF BRITISH COLUMBIA",
        clientLogo: "",
        description:
          "Countable built a custom-designed web-based mapping service for Groundwater Wells and Aquifers (GWELLS), Ministry of Environment, Province of BC.",
        features: [],
        projectTeaserImg: "",
        testimonial: {}
      },
      {
        client: "ECOTRUST CANADA",
        clientLogo: "",
        description:
          "A first-of-its-kind custom-designed web-based mapping tool for Ecotrust Canada. Spyglass is the first mapping tool that puts illegal, unreported and unregulated fishing front and centre.",
        features: [],
        projectTeaserImg: "",
        testimonial: {}
      }
    ]
  }
];

const features = [
  {
    title: "",
    description: ""
  },
  {
    title: "",
    description: ""
  },
  {
    title: "",
    description: ""
  },
  {
    title: "",
    description: ""
  },
  {
    title: "",
    description: ""
  }
];

export default {
  getTestimonials(items) {
    items(testimonials);
  },
  getTeamMembers() {
    return teamMembers;
  },
  getProjectList(items) {
    items(ourProjects);
  },
  getServices(items) {
    items(services);
  }
};
