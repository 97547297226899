// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import store from "./store";
import router from "./router";

Vue.config.productionTip = false;

export default function createApp(context) {
  const app = {
    el: "#app",
    router,
    store,
    head: {},
    render: h => h(App)
  };

  return app;
}
