import Vue from "vue";
import Vuex from "vuex";
import Data from "@/api/Data";
import API from "@/api/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    testimonials: [],
    teamList: [],
    projectList: [],
    servicesList: [],
    clientList: [],
    blogList: [],
    blogDetail: {},
    jobPostingList: [],
    isLoading: false,
  },

  getters: {
    testimonialList(state) {
      return state.testimonials;
    },

    getTeamList(state) {
      return state.teamList
        .sort((a, b) => b.employee_level - a.employee_level)
        .filter((member) => member.is_active);
    },

    getProjectList(state) {
      return state.projectList;
    },

    getBlogList(state) {
      return state.blogList.sort((a, b) => {
        return b.id - a.id;
        //return Date.parse(b.created_at) - Date.parse(a.created_at);
      });
    },

    getJobPostingList(state) {
        return state.jobPostingList.sort((a, b) => {
          return b.id - a.id;
        });
    },

    getMember(state) {
      return (param) =>
        state.teamList.find((member) => member.name.includes(param));
    },
    getServiceList(state) {
      return state.servicesList.sort((a, b) => {
        return a.service_name.length - b.service_name.length;
      });
    },
    getService(state) {
      return (param) =>
        state.servicesList.find((service) => service.service_slug === param);
    },

    getBlog(state) {
      return (param) => state.blogList.find((blog) => blog.id === param);
    },
  },

  actions: {
    // Fetch dummy data
    fetchTestimonial({ commit }) {
      Data.getTestimonials((testimonial) => {
        commit("setTestimonial", testimonial);
      });
    },
    fetchTeamMembers({ commit }) {
      commit("setTeamMembers", Data.getTeamMembers());
    },
    fetchProjectList({ commit }) {
      Data.getProjectList((project) => {
        commit("setProjects", project);
      });
    },
    fetchServicesList({ commit }) {
      Data.getServices((services) => {
        commit("setServices", services);
      });
    },

    //Fetch from STRAPI API
    fetchServicesAPI({ commit }) {
      API.getAPI("/services").then((response) => {
        commit("setServices", response.data);
      });
    },

    fetchCaseStudy(context, id) {
      return API.getAPI(`/projects/${id}`).then((response) => {
        return response.data;
      });
    },

    fetchProjectsAPI({ commit }) {
      API.getAPI("/projects/").then((response) => {
        commit("setProjects", response.data);
      });
    },

    fetchTeamMemberAPI({ commit }) {
      API.getAPI("/teams/").then((response) => {
        commit("setTeamMembers", response.data);
      });
    },

    fetchClientsAPI({ commit }) {
      API.getAPI("/clients/").then((response) => {
        commit("setClients", response.data);
      });
    },

    fetchTestimonialAPI({ commit }) {
      API.getAPI("/testimonials").then((response) => {
        commit("setTestimonial", response.data);
      });
    },

    fetchBlogAPI({ commit }) {
      commit("setLoading", true)
      API.getAPI("/blogs")
        .then((response) => {
          commit("setBlogList", response.data);
        })
        .catch((error) => {
          console.error(error);
          commit("setLoading", false); // Set loading to false in case of an error
      });

    },
    fetchBlogDetail({ commit, dispatch }, id) {
      API.getAPI(`/blogs/${id}`).then((response) => {
        commit("setBlogDetail", response.data);
        dispatch("fetchBlogAPI");
      });
    },
    fetchJobPostingAPI({ commit }) {
      commit("setLoading", true); // Set loading to true before making the API call
      API.getAPI("/job-postings")
        .then((response) => {
          commit("setJobPostingList", response.data);
        })
        .catch((error) => {
          console.error(error);
          commit("setLoading", false); // Set loading to false in case of an error
      });
    },
  },

  mutations: {
    setTestimonial(state, testimonial) {
      state.testimonials = testimonial;
    },
    setTeamMembers(state, team) {
      state.teamList = team;
    },
    setProjects(state, project) {
      state.projectList = project;
    },
    setServices(state, services) {
      state.servicesList = services;
    },
    setClients(state, clients) {
      state.clientList = clients;
    },
    setBlogList(state, blogs) {
      state.blogList = blogs;
      state.isLoading = false;
    },
    setBlogDetail(state, blog) {
      state.blogDetail = blog;
    },
    setJobPostingList(state, jobPosting) {
      state.jobPostingList = jobPosting;
      state.isLoading = false;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
  },
});
