<template>
  <!-- eslint-disable -->
  <body id="app" v-if="hasLoaded">
    <Header />
    <router-view />
    <component :is="switchFooter" />
  </body>
  <div v-else class="loading-section">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Footer1 from "@/components/Footer1";
import Entry from "@/assets/js/entry";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Footer1,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    switchFooter() {
      return this.$route.path == "/" ? Footer : Footer1;
    },
  },
  mounted() {
    this.$store.dispatch("fetchServicesAPI").then((response) => {
      setTimeout(() => (this.hasLoaded = true), 500);
    });
  },
  methods: {},
};
</script>

<style lang="scss">
@import "./assets/sass/style.scss";
</style>
