import axios from "axios";

export default class Utils {
  static getBaseURL() {
    return process.env.VUE_APP_STRAPI;
  }

  static getAPI(url) {
    return axios
      .get(`${this.getBaseURL()}${url}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        return response;
      })
      .finally(() => {})
      .catch(error => {
        console.log(error);
      });
  }

  static postAPI(url, body) {
    return axios
      .post(`${this.getBaseURL()}${url}`, {
        headers: { "Content-Type": "application/json" },
        body: body
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
