<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="row">
            <div class="col-md-6 pr">
              <div class="footer-l">
                <h3 style="text-align: right;">Company</h3>
                <ul>
                  <li>
                    <a
                      href="https://countable-ops-manual.readthedocs.io/index.html"
                      target="_blank"
                    >Operations Manual</a>
                  </li>
                  <li>
                    <a href="https://www.reddit.com/r/countableweb" target="_blank">Board</a>
                  </li>
                  <li>
                    <a
                      href="https://countable-ops-manual.readthedocs.io/peopleops/recruiting/APPLICANT_GUIDE.html?highlight=applicant#applicant-guide"
                      target="_blank"
                    >Applicant's Guide</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 pl">
              <div class="footer-r">
                <h3>Product</h3>
                <ul>
                  <li>
                    <a href="https://htmlcommentbox.com/">HTML Comment Box</a>
                  </li>
                  <li>
                    <a href="http://bawkbox.com">Bawkbox</a>
                  </li>
                  <li>
                    <a href="https://cryptsend.io/">CryptSend</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="social-icon">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/countable-web-productions"
                  target="_blank"
                >
                  <img src="@/assets/media/landing/linkdin.svg" alt="countable linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/countableweb/" target="_blank">
                  <img src="@/assets/media/landing/Group_167.svg" alt="countable instagram" />
                </a>
              </li>
              <li>
                <a href="https://dribbble.com/countable-web" target="_blank">
                  <img src="@/assets/media/landing/Group_166.svg" alt="countable dribble" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
