import { Carousel } from "./classes/Carousel";
const $ = window.$;

$(function() {
  //first, load only the first customers
  Carousel.renderCustomer();

  //then if user clicks right, move right
  $(".carousel-arrow-right").on("click", function() {
    $(this).addClass("move-arrow-right");
    setTimeout(() => {
      $(this).removeClass("move-arrow-right");
    }, 200);
    Carousel.next();
  });

  //if clicks left, move left...
  $(".carousel-arrow-left").on("click", function() {
    $(this).addClass("move-arrow-left");

    setTimeout(() => {
      $(this).removeClass("move-arrow-left");
    }, 200);

    Carousel.previous();
  });
});
